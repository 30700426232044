import { useEffect, useState } from "react";
import styles from "./QABlock.module.scss";

const QABlock = ({ question, answer, id, toggle, initiallyOpen = false }) => {
    
    const [isOpen, setIsOpen] = useState(initiallyOpen);
    
    useEffect(() => {
        setIsOpen(initiallyOpen);
    }, [initiallyOpen]);
    
    const toggleAnswer = () => setIsOpen((prev) => !prev);
    
    return (
        <div className={styles.block} id={id}>
            <div className={styles.question} onClick={toggleAnswer} >
                <h3>{question}?</h3>
                <img
                    src={toggle.src}
                    alt={toggle.alt}
                    className={`${styles.toggle} ${isOpen ? styles.open : ""}`}
                />
            </div>
            <div className={`${styles.answer} ${isOpen ? styles.reveal : ""}`}>
                <p>{answer}</p>
            </div>
        </div>
    )
}

export default QABlock;
import logo from "../../../assets/Awntech-logo.png";
import locationPin from "./imgs/location-pin.svg";
import phoneIcon from "./imgs/phone-icon.svg";
import homeIcon from "./imgs/home-icon.svg";
import contactIcon from "./imgs/contact-icon.svg";
import hoursIcon from "./imgs/hours-icon.svg"; 

const content = {
    logo: {
        src: logo,
        alt: ""
    },
    locationPin: {
        src: locationPin,
        alt: ""
    },
    phoneIcon: {
        src: phoneIcon,
        alt: ""
    },
    homeIcon: {
        src: homeIcon,
        alt: ""
    },
    contactIcon: {
        src: contactIcon,
        alt: ""
    },
    hoursIcon: {
        src: hoursIcon,
        alt: ""
    },
    contacts: {
        heading: "Get In Touch",
        locations: [
            {
                street: "4530 63rd Cir N",
                city: "Pinellas Park, FL 33781",
                link: "https://google.com/maps?q=4530+63rd+Cir+N,+Pinellas+Park,+FL+33781"
                // https://google.com/maps?q=123+Location+Rd,+City,+ST+55555
            },
        ],
        phoneNumbers: [
            {
                number: "(727) 528-3918",
                link: "tel:+17275283918"
            }
        ]
    },
    navigation: {
        heading: "Quick Links",
        links: [
            {
                text: "Awnings",
                destination: "/awnings",
                id: "hero"
                // future heroes will have a "hero" id, so clicking this link will 
                //  send to the top of the page
            },
            {
                text: "Canopies",
                destination: "/canopies",
                id: "hero"
            },
            {
                text: "Re-Covers",
                destination: "/re-covers",
                id: "hero"
            },
            {
                text: "Shutters",
                destination: "/shutters",
                id: "hero"
            },
            {
                text: "Contact Us",
                target: "/contact-us",
                id: "hero"
            }
        ]
    },
    hours: {
        heading: "Hours",
        times: [
            {
                days: "M-F",
                hours: "9:30am - 5:30pm"
            },
            {
                days: "Sat",
                hours: "10:00am - 4:00pm"
            },
            {
                days: "Sun",
                hours: "10:00am - 4:00pm"
            }
        ]
    }
}

export default content;
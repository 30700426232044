import styles from "./Banner.module.scss";
import fallback from "./content";

const Banner = ({ content = fallback }) => {
    const {
        heading
    } = content;
    return (
        <div className={styles.banner}>
            <h2>{heading}</h2>
        </div>
    )
}

export default Banner;
import { Link } from "react-router-dom";
import { useSendToSection } from "../../hooks";
import styles from "./Mobile.module.scss";
import content from "../../content";
import { useState } from "react";
const {
    locationPin, phoneIcon, homeIcon,
    contacts, contactIcon, hoursIcon, hours
} = content;

const Mobile = () => {
    const [showHours, setShowHours] = useState(false);
    const toggleHours = () => setShowHours(showing => !showing);
    
    return (
        <footer className={`${styles.footer} ${showHours ? styles.open : ""}`}>
            <ul className={styles.content}>
                <li className={styles.homeBlock}>
                    <Link
                        to={"/"} className={styles.link}
                        onClick={() => useSendToSection("home")}
                    >
                        <img src={homeIcon.src} alt={homeIcon.alt} />
                        <span>Home</span>
                    </Link>
                </li>
                <li className={styles.locationBlock}>
                    <Link
                        to={contacts.locations[0].link}
                        className={styles.link}
                    >
                        <img src={locationPin.src} alt={locationPin.alt} />
                        Location
                    </Link>
                </li>
                <li className={styles.phoneBlock}>
                    <Link
                        to={contacts.phoneNumbers[0].link}
                        className={styles.link}
                    >
                        <img src={phoneIcon.src} alt={phoneIcon.alt} />
                    </Link>
                </li>
                <li className={styles.contactBlock}>
                    <Link
                        to={"/link-2"} className={styles.link}
                        onClick={() => useSendToSection("home")}
                    >
                        <img src={contactIcon.src} alt={contactIcon.alt} />
                        Contact
                    </Link>
                </li>
                <li className={styles.contactBlock}>
                    <div className={styles.toggle} onClick={toggleHours}>
                        <img src={hoursIcon.src} alt={hoursIcon.alt} />
                        {showHours ? "Hide" : "Hours"}
                    </div>
                </li>
            </ul>
            <div className={`${styles.sidebar} ${showHours ? styles.open : ""}`}>
                <ul className={styles.hoursList}>
                    {hours.times.map((times, id) => (
                        <li key={id}>
                            <span>{times.days}</span>
                            <span>{times.hours}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    )
}

export default Mobile;
import logo from "../../../assets/Awntech-logo.png";
import burger from "./imgs/hamburger.svg";
import cancel from "./imgs/cancel.svg";

const content = {
    logo: {
        src: logo,
        alt: ""
    },
    burger: {
        src: burger,
        alt: ""
    },
    cancel: {
        src: cancel,
        alt: ""
    },
    links: [
        {
            text: "Awnings",
            target: "/awnings",
            dropdown: [
                // {
                //     text: "Sublink",
                //     target: "/link-1?sublink=1"
                // },
                // {
                //     text: "Sublink",
                //     target: "/link-1?sublink=2"
                // }
            ]
        },
        {
            text: "Canopies",
            target: "/canopies",
            dropdown: [
                // {
                //     text: "Sublink",
                //     target: "/link-2?sublink=1"
                // },
                // {
                //     text: "Sublink",
                //     target: "/link-2?sublink=2"
                // }
            ]
        },
        {
            text: "Re-Covers",
            target: "/re-covers",
            dropdown: []
        },
        {
            text: "Shutters",
            target: "/shutters",
            dropdown: []
        },
        {
            text: "Contact Us",
            target: "/contact-us",
            dropdown: []
        }
    ]
}

export default content;

const content = {
    heading: "Pinellas County's\nGo-To Awning and\nCanopy Manufacturer",
    subheading: "Partnering with General Contractors and Developers since 1992",
    description: 
        "Awn Tech is the leading choice for residential and commercial\n awning " +
        "solutions. Discover what sets Awn Tech apart and\n elevate your outdoor spaces " +
        "above the rest.",
    
}

export default content;
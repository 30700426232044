import { useEffect, useState } from "react";
import {
  getFaqQueue,
  clearFaqQueue,
  onFaqQueueUpdate
} from "../../shared/faqQueueStore";

const useGetFaqQueue = () => {
  const [openIds, setOpenIds] = useState(getFaqQueue());

  useEffect(() => {
    const unsubscribe = onFaqQueueUpdate((ids) => {
      setOpenIds(ids);
      clearFaqQueue();
    });

    return unsubscribe;
  }, []);

  return openIds;
};

export default useGetFaqQueue;

const seoMap = {
    "/": {
        tags: (
            <>
                <title>Awn Tech | Custom Awnings & Canopies in St. Petersburg & Tampa Bay</title>
                <meta
                    name="description"
                    content="Awn Tech designs, fabricates, and installs premium awnings and canopies for homes and businesses across St. Petersburg, Pinellas County, and the Tampa Bay area. Serving Florida since 1992."
                />
                <meta
                    name="keywords"
                    content="Awnings St. Petersburg, Canopies Pinellas County, Custom Shade Structures, Awning Contractor Tampa Bay, Commercial Awnings Florida, Residential Awnings"
                />
                <meta name="author" content="Awn Tech" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://awntechfl.com" />
                
                {/* Open Graph */}
                
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://awntechfl.com" />
                <meta property="og:title" content="Awn Tech | Custom Awnings & Canopies in St. Petersburg & Tampa Bay" />
                <meta
                    property="og:description"
                    content="Trusted by general contractors for 30+ years. We deliver on-time, on-budget awning and canopy solutions across Florida."
                />
                <meta property="og:image" content="https://awntechfl.com/assets/og-image.jpg" />
                
                {/* Twitter Card */}
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://awntechfl.com" />
                <meta name="twitter:title" content="Awn Tech | Custom Awnings & Canopies in Tampa Bay" />
                <meta name="twitter:description" content="High-quality shade structures for commercial and residential spaces in St. Pete and beyond." />
                <meta name="twitter:image" content="https://awntechfl.com/assest/og-image.jpg" />
            </>
        ),
        script: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Awn Tech",
            image: "https://awntechfl.com/assets/og-image.jpg",
            url: "https://awntechfl.com",
            telephone: "+1-727-528-3918",
            address: {
                "@type": "PostalAddress",
                streetAddress: "4530 63rd Cir N",
                addressLocality: "Pinellas Park",
                addressRegion: "FL",
                postalCode: "33781",
                addressCountry: "US"
            },
            geo: {
                "@type": "GeoCoordinates",
                latitude: 27.8370,
                longitude: -82.6996
            },
            openingHoursSpecification: [
                {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                    opens: "09:30",
                    closes: "17:30"
                },
                {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: ["Saturday", "Sunday"],
                    opens: "10:00",
                    closes: "16:00"
                }
            ],
            sameAs: [
                "https://www.facebook.com/awntechfl",
                "https://www.instagram.com/awntechfl"
            ],
            description: "Awn Tech is a leading manufacturer and installer of custom awnings and canopies for residential and commercial clients across Pinellas County and the greater Tampa Bay area."
        }
    }
}

export default seoMap;
import toggleArrow from "./imgs/toggle-arrow.svg";

const content = {
    toggle: {
        src: toggleArrow,
        alt: ""
    },
    faqs: [
        {
            question: "What areas do Awn Tech Service",
            answer:
                "We proudly serve Pinellas, Hillsborough, and Manatee Counties, " +
                "as well as anywhere within a two-hour drive of our St. Petersburg " +
                "location.",
            id: "faq-areas"
        },
        {
            question: "What products do Awn Tech specialize in",
            answer:
                "Awn Tech specializes in custom aluminum architectural products, including " +
                "awnings, canopies, walkways, Bahama shutters, roof scenes, louvers, " +
                "sunshades, railings, and carports.",
            id: "faq-products"
        },
        {
            question: "How long has Awn Tech been in business",
            answer:
                "Awn Tech has been proudly surving the Tampa Bay area for over 29 years.",
            id: "faq-years-active"
        },
        {
            question: "How can I request a quote",
            answer:
                "You can reach out through our contact form below, or give us a call. We'll " +
                "gather some basic information and provide a detailed estimate.",
            id: "faq-quote"
        },
        {
            question: "Does Awn Tech offer powder coating",
            answer:
                "While Awn Tech doesn't provide in-house powder coating, we coordinate with " +
                "trusted local vendors to ensure your project receives a high-quality, durable " +
                "finish.",
            id: "faq-powder"
        },
        {
            question: "Can Awn Tech help with engineering and permitting",
            answer:
                "Yes! We handle engineering and permitting as part of our project process, " +
                "which ensures your installation meets all local codes and requirements.",
            id: "faq-engineering-permitting"
        },
        {
            question: "What materials do you use for your products",
            answer:
                "We primarily fabricate with high-grade aluminum for its strength, durability, " +
                "and resistance to rust and corrosion - ideal for Florida's climate.",
            id: "faq-materials"
        },
        {
            question: "Do you offer custom fabrication",
            answer:
                "Absolutely! Every Awn Tech product is custom-built to meet the specific " +
                "requirements of each project, rather than relying on off-the-shelf solutions.",
            id: "faq-fabrication"
        }
    ]
}

export default content;
import designIcon from "./imgs/design-icon.png";
import fabricationIcon from "./imgs/fabrication-icon.png";
import installIcon from "./imgs/install-icon.png";

const cards = [
    {
        icon: {
            src: designIcon,
            alt: ""
        },
        heading: "Design",
        description:
            "We carefully assess the specific requirements and preferences " +
            "of our clients, ensuring that the awnings align with their vision " +
            "and needs. Through a combination of creative thinking and technical " +
            "expertise, we strive to create awnings that are not only visually " +
            "pleasing, but also highly functional and durable, resulting in a " +
            "harmonious blend of form and function."
    },
    {
        icon: {
            src: fabricationIcon,
            alt: ""
        },
        heading: "Fabrication",
        description:
            "Our manufacturing process is driven by precision and quality " +
            "craftsmanship. We utilize cutting-edge technology and machinery " +
            "to fabricate awnings with utmost accuracy and attention to detail. " +
            "Our skilled team of artisans and technicians work dilligently to " +
            "ensure that every component is expertly crafted, resulting in " +
            "awnings that are built to last and withstand various " +
            "weather conditions."
    },
    {
        icon: {
            src: installIcon,
            alt: ""
        },
        heading: "Installation",
        description:
            "Our installation process is seamless and efficient, using an " +
            "experienced team to ensure that each awning is carefully positioned " +
            "and securely fastened. We focus on structural integrity and proper " +
            "alignment to ensure that once the awnings go up, they're installed to " +
            "last. Our hassle-free installation guarantees that we can provide the " +
            "desired shade and beaty to any space."
    }
]

export default cards;
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavbarTypeA as Navbar } from "./components/Navbars";
import { FooterTypeA as Footer } from "./components/Footers";
import HomePage from "./components/HomePage/HomePage";
import NotFound from "./components/NotFound/NotFound";
import { useIsPageTop } from "./components/Navbars/NavbarTypeA/hooks";
import { useRef } from "react";

function App() {
  const location = useLocation();
  const scrollRef = useRef(null)
  const isTop = useIsPageTop(scrollRef);
  
  return (
    <>
      <Navbar isTop={isTop} />
      <Routes>
        <Route path="/" element={<HomePage scrollRef={scrollRef} key={location.pathname} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App

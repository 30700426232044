import styles from "./FAQs.module.scss";
import content from "./content";
import { ButtonTypeB as Button } from "../../../Buttons";
import { useSendToSection } from "../ServicePreview/hooks";
import QABlock from "./components/QABlock/QABlock";
import useGetFaqQueue from "./hooks/useGetFaqQueue";

const FAQs = () => {
    const openIds = useGetFaqQueue();
    if (openIds === null) return null;
    
    console.log("openIds from queue:", openIds);
    
    const { toggle, faqs } = content;
    
    return (
        <section className={styles.faqs} id="faqs">
            <div className={styles.container}>
                <div className={styles.titleBlock}>
                    <h2>FAQs</h2>
                    <p>Find answers to common questions below:</p>
                </div>
                <div className={styles.content}>
                    {/* {faqs.map(({ question, answer, id }, idx) => ( */}
                    {faqs.map(({ question, answer, id }, idx) => {
                        const shouldOpen = openIds.includes(id);
                        console.log("QABlock id:", id, "should open", shouldOpen);
                       
                        return (
                            <QABlock
                            key={idx} toggle={toggle}
                            question={question}
                            answer={answer} id={id}
                            initiallyOpen={openIds.includes(id)}
                            />
                        )
                    })}
                    {/* ))} */}
                </div>
                <div className={styles.followUp}>
                    <h2>Still have questions?</h2>
                    <p>We're here to help!</p>
                    <Button 
                        text={"Contact Us"}
                        target={"/contact-us"}
                        onClick={() => useSendToSection("hero")}
                    />
                </div>
            </div>
        </section>
    )
}

export default FAQs;
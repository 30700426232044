import star from "./imgs/star.svg";
import reviews from "./components/Card/reviews";

const content = {
    heading: "Here's What People Are About Us",
    subheading:
        "See firsthand what our amazing customers are saying about us",
    company: "Awn Tech",
    star,
    rating: 4.8,
    amount: 22,
    reviews
}

export default content;
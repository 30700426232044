import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import seoMap from "./seoMap";
import useStructuredData from "./useStructuredData";

const SEOHelmet = () => {
    const { pathname } = useLocation();
    const entry = seoMap[pathname];
    
    if (!entry) return null;
    
    const { tags, script } = entry;
    
    useStructuredData(script);
    
    return <Helmet>{tags}</Helmet>
}

export default SEOHelmet;
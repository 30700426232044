import styles from "./Cards.module.scss";
import Card from "./components/Card";
import cards from "./content";

const Cards = () => {
    return (
        <section className={styles.cards}>
            <div className={styles.container}>
                {cards.map((card, id) => (
                    <Card key={id} content={card} />
                ))}
            </div>
        </section>
    )
}

export default Cards;
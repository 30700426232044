import hero from "./imgs/placeholder.png";

const content = {
    hero: {
        src: hero,
        alt: ""
    },
    heading: "This is a big heading for the Service Preview",
    description: 
        "This is a paragraph set up to describe the service that the " +
        "company is advertising. Here you should throw in a good description " +
        "of what sets you apart, as well as any buzzwords and key terms you want " +
        "to influence Seach Engine Optimization, that way potential customers are " +
        "more likely to find your page in the first place.",
    cta: {
        primary: {
            text: "Call to Action",
            target: "/link-1",
            sectionId: "hero"
        },
        secondary: {
            text: "Learn More",
            target: "/contact-us",
            sectionId: "hero"
        }
    },
    mirrored: false
}

export default content;
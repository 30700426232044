import icon from "./imgs/icon.svg";

const content = {
    icon: {
        src: icon,
        alt: ""
    },
    heading: "Heading",
    description:
        "This description should be roughly three medium length " +
        "sentences. This should be enough to fill up about one-third of " +
        "the screen's length at a moderate font-size inside a column 1/3rd " +
        "of the screen's width. Be sure to include buzzwords for Search Engine " +
        "Optimization purposes.",
}

export default content;
import { useState, useRef, useEffect } from "react";

const useIsOverflowing = () => {
    const contentRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    
    useEffect(() => {
        const element = contentRef.current;
        if (!element) return;
        
        const checkOverflowing = () => {
            setIsOverflowing(
                element.scrollHeight > element.clientHeight
            );
        }
        
        checkOverflowing();
            
        window.addEventListener("resize", checkOverflowing);
        return () => window.removeEventListener("resize", checkOverflowing);
        
    }, []);
    
    return [contentRef, isOverflowing];
}

export default useIsOverflowing;
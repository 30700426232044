
const reviews = [
    {
        name: "Rick Kourchenko",
        rating: 5,
        profile: "https://lh3.googleusercontent.com/a/ACg8ocJidIn97Qzd0cuX0Ax3HIkHwRl4UwS5riJ6UuSys-V49-QEhg=w45-h45-p-rp-mo-br100",
        content:
            "My front door had no coverage so I wanted to install some kind of " +
            "awning/overhang and met with a few different companies to get quotes. " +
            "Gina, Tim, and team were incredible. Collaborated on the design and " +
            "came up with something I love. It's built/installed to withstand the " +
            "weather and they even used a color to match the trim of the house. Very " +
            "distinct and I'm really happy with it. They do all types of awnings " +
            "and might even design/build an enclosure for my back patio. Huge thanks " +
            "to the entire team!",
        link: "https://maps.app.goo.gl/9YQNfYpXPMFAfJT48"
    },
    {
        name: "Monique Curran",
        rating: 5,
        profile: "https://lh3.googleusercontent.com/a-/ALV-UjVoc0C1V9vgKJsv2Vf3S7MVS_wYQM7bwzLwUYZOA6uU1g3noNo=w45-h45-p-rp-mo-br100",
        content:
            "Love my new awning for front and back door. Back door come with a " +
            "bonus I can remove the whole awning if/when hurrican approach.",
        link: "https://maps.app.goo.gl/pj7y6Mbkr7k3DeGu5"
    },
    {
        name: "Tom Quay",
        rating: 5,
        profile: "https://lh3.googleusercontent.com/a/ACg8ocL6PT1x3UghdUnzzbePh6TYUKuBRAqA14Mdl2p6FmL4Vsph8g=w45-h45-p-rp-mo-br100",
        content:
            "I ordered the motorized Destin . I also got the vibration sensor " +
            "to close the awning if it to windy. The remote and sensor were easy " +
            "to program, even for me !! The awning helps keep my lanai (man cave) " +
            "much cooler and I can raise the blinds and view my back yard. I needed to " +
            "make a couple of adjustments after I installed it and all of the adjusters " +
            "are in easy accessible areas. Smart engineering.",
        link: "https://maps.app.goo.gl/bABXndA1QSsNDnb79"
    },
    {
        name: "betsy dailey",
        rating: 5,
        profile: "https://lh3.googleusercontent.com/a/ACg8ocIoGYhAC0kuQ7tRTSuFQxIIZp9FqXABsrR2H0ejxgH41DqyNA=w45-h45-p-rp-mo-br100",
        content:
            "From start to finish AwnTech was a excellent company to work with. They " +
            "assisted in creating, producing, and installing my lovely awning, and all in " +
            "warp speed! It was a pleasure to work with their employees... all very " +
            "professional, polite, and friendly. I would highly recommend them. If you are " +
            "looking for great, professional service, at a very fair price... call them! " +
            "Now I'm excited to have my landscaping done to finish the exterior of my home! " +
            "THANK YOU AWN TECH!",
        link: "https://maps.app.goo.gl/5JKSoXy5S1Uw1P2cA"
    }
]

export default reviews
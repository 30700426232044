import { useState } from "react";
import styles from "./Card.module.scss";
import { useIsOverflowing } from "./hooks";

const Card = ({ review, stars }) => {
    const {
        name, profile, content, link
    } = review;
    const [isExpanded, setIsExpanded] = useState(false);
    const [contentRef, isOverflowing] = useIsOverflowing();
    return (
        <div className={`${styles.card} ${isExpanded ? styles.expanded : ""}`}>
            <div className={styles.stars}>{stars}</div>
            <p ref={contentRef} className={`${styles.content} ${isExpanded ? styles.full : ""}`}>
                {content}
            </p>
            <div className={styles.author}>
                <a className={styles.link} href={link} target="_blank">
                    <img src={profile} />
                    <span className={styles.name}>{name}</span>
                </a>
                {isOverflowing && (
                    <span
                        className={`${styles.toggle} ${isExpanded ? styles.active : ""}`}
                        onClick={()=> setIsExpanded(!isExpanded)}
                        >
                    {isExpanded ? "See Less" : "See More"}
                    </span>
                )}
            </div>
        </div>
    )
}

export default Card;
import styles from "./Card.module.scss";
import fallback from "./content";

const Card = ({ content = fallback }) => {
    const { icon, heading, description } = content;
    
    return (
        <div className={styles.card}>
            <div className={styles.icon}>
                <img src={icon.src} alt={icon.alt} />
            </div>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.description}>{description}</p>
        </div>
    )
}

export default Card;
import placeholder from "./imgs/placeholder.png";

const services = [
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "On Time, On Budget Development Projects",
        description:
            "Experience dealing with large projects is a MUST when deciding " +
            "which company to sub-contract your canopies and awnings to. Awn Tech " +
            "has been completeing large-scale projects with general contractors and " +
            "developers for over 25 years. Our superb track record of completing projects " +
            "on time, on budget, and with high quaility results is what helps make our " +
            "commercial manufacturing program so successful.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: ""
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faqs",
                scope: "#page-content",
                faqIds: [
                    "faq-products",
                    "faq-years-active",
                    "faq-quote"
                ]
            }
        },
        mirrored: false
    },
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "Expert Fabrication At Any Scale",
        description:
            "Choosing the right partner for fabrication is critical when your project demands " +
            "precision and reliability. Awn Tech specializes in custom fabrication solutions designed " +
            "to meet the needs of contractors, developers, and architects alike. With decades of " +
            "experience, we can ensure every component is built to spec, on schedule, and with the " +
            "highest attention to detail.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: "hero"
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faq-materials",
                scope: "#page-content",
                faqIds: [
                    "faq-materials",
                    "faq-fabrication",
                ]
            }
        },
        mirrored: true
    },
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "Flawless Installation, Start to Finish",
        description:
            "Proper installation is key, and Awn Tech gets it right the first time. With our " +
            "dedicated team of experienced installers, we can ensure your awnings and canopies " +
            "are fitted, securely, safely, and quickly. Our commitment to the best possible " +
            "installation process makes us a reliable partner from start to finish.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: "hero"
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faq-engineering-permitting",
                scope: "#page-content",
                faqIds: [
                    "faq-engineering-permitting",
                ]
            }
        },
        mirrored: false
    },
]

export default services;
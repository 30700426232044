import { Link, useLocation } from "react-router-dom";
import styles from "./ButtonTypeB.module.scss";

const ButtonTypeB = ({ target, text, onClick }) => {
    const isExternalLink = target.startsWith("http");
    const location = useLocation();
    
    const handleClick = (e) => {
        const isSamePage = location.pathname === target;
        if (onClick && isSamePage) {
            e.preventDefault();
            onClick();
        }
    }
    
    return isExternalLink ? (
        <a href={target} target="_blank" rel="noopener noreffer" className={styles.button}>
            {text} &rarr;
        </a>
    ) : (
        <Link to={target} className={styles.button} onClick={onClick ? handleClick : undefined}>
            {text} &rarr;
        </Link>
    )
}

export default ButtonTypeB;
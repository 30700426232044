let queuedFaqIds = [];
let listeners = [];

export const setFaqQueue = (ids) => {
    console.log("setFaqQueue called with:", ids);
    queuedFaqIds = ids;
    listeners.forEach((fn) => fn(ids));
}

export const getFaqQueue = () => queuedFaqIds;

export const clearFaqQueue = () => {
    queuedFaqIds = [];
}

export const onFaqQueueUpdate = (fn) => {
    listeners.push(fn);
    return () => {
        listeners = listeners.filter((cb) => cb !== fn);
    }
}
// import { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";

// const useIsPageTop = () => {
//     const [isTop, setIsTop] = useState(true);
//     const location = useLocation();
    
//     useEffect(() => {
//         const pageContent = document.getElementById("page-content");
//         if (!pageContent) return;
        
//         const handleScroll = () => {
//             setIsTop(pageContent.scrollTop === 0);
//         }
        
        
//         pageContent.addEventListener("scroll", handleScroll);
//         handleScroll();
        
//         return () => window.removeEventListener("scroll", handleScroll);
//     }, [location.pathname]);
    
//     return isTop;
// }

// export default useIsPageTop;

import { useState, useEffect } from 'react';

function useIsPageTop(containerRef) {
  const [isPageTop, setIsPageTop] = useState(true); // assume at top initially

  useEffect(() => {
    const container = containerRef?.current || window;  // use provided container or window

    const handleScroll = () => {
      if (container === window) {
        // Use window.scrollY if the scroll container is the window
        setIsPageTop(window.scrollY === 0);
      } else {
        // Use scrollTop for custom scrollable element
        setIsPageTop(container.scrollTop === 0);
      }
    };

    // Attach scroll listener
    container.addEventListener('scroll', handleScroll);
    handleScroll();  // initial check on mount

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef]);

  return isPageTop;
}

export default useIsPageTop;